import React from 'react'
import { Link } from 'gatsby'

const Nav = class extends React.Component {
  

  render() {
    return (
      
     
          <nav
            className="navbar"
            role="navigation"
            aria-label="main-navigation"
          >
            <div className="text-center">
              <Link className="navbar-item" to="/">
                2024-2025 Season
              </Link>
              <Link className="navbar-item" to="/2023-2024">
                2023-2024 Season
              </Link>
              <Link className="navbar-item" to="/2022-2023">
                2022-2023 Season
              </Link>
              <Link className="navbar-item" to="/2021-2022">
                2021-2022 Season
              </Link>
              <Link className="navbar-item" to="/2020-2021">
                2020-2021 Season
              </Link>
              <Link className="navbar-item" to="/legacy">
                Legacy Games
              </Link>
            </div>
            
          </nav>
       
    )
  }
}

export default Nav
