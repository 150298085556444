import React from 'react'

// import Nav from '../components/Nav'

// import logo from '../img/northcoastlogo.png'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer">
        <div className='inside-xxl'>
          {/* <div className='logo inside-xxxs text-center'>
            <img src={logo} alt='North Coast Game of the Week' />
          </div> */}
        
          <p>Thomas Whaley has been producing North Coast Game of the Week for more than 20 years. We appreciate our huge fan base and strive to bring you, the viewer, the best possible coverage.<br/>

          Thank you to the athletes, coaches, family, teachers, and administrators who make high school sports so much a part of living on the North Coast.</p>
        </div>

       <div><a className='button' href='mailto:twteleproductions@mac.com'>Contact Us</a></div>
        
      </footer>
    )
  }
}

export default Footer
