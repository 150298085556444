import React from 'react'
import Nav from '../components/Nav'
import logo from '../img/northcoastlogo.png'
import civista from '../img/civista.png'
import facebook from '../img/social/facebook.svg'
import { Link } from 'gatsby'
const Navbar = class extends React.Component {
  

  render() {
    return (
      <div className='header'>

        <div className='inside-xl'>

          <div className='always-flex justify social'>
            <div className='civista text-center'>
              <img src={civista} alt='Civista Bank'/>
              <span>Presents</span>
            </div>
            <div className='facebook'><a rel="noreferrer" target='_blank' href='https://www.facebook.com/northcoastgameofweek'><img src={facebook} alt='Facebook' /></a></div>
          </div>

          <div className='logo inside-xs text-center'>
            <Link to='/'><img src={logo} alt='North Coast Game of the Week' /></Link>
          </div>

     
          <Nav />
          
        </div>
      </div>
    )
  }
}

export default Navbar
